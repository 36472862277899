import React from 'react';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import Challenge from '@src/components/shared/challenge';
import Gallery from '@src/components/shared/gallery';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

const Certifications = () => (
  <Layout pageName={pages.certifications.name}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">Certifications</h1>
      <div className="subheading">
        We are ISO 9001, AS9100, and IATF 16949 registered
      </div>
    </div>
    <section className="c-content-centered h-pt-40 h-pb-40">
      <h3>ISO 9001:2015</h3>
      <p>
        <a href="https://www.iso.org/standard/62085.html" target="_blank"
          rel="noopener noreferrer">ISO 9001:2015</a> is a quality management standard
        developed by the International Organization for Standardization (ISO). The standard
        can be applied to any organization that needs to demonstrate its ability to
        consistently provide products and services that enhance customer satisfaction and
        provide assurance of conformity to customer and applicable statutory and regulatory
        requirements.
      </p>
      <h3 className="h-mt-40">IATF 16949:2016</h3>
      <p>
        <a href="https://www.iatfglobaloversight.org/" target="_blank"
          rel="noopener noreferrer">IATF 16949:2016</a> is a technical specification
        for the automotive sector quality management systems, developed by the International
        Automotive Task Force (IATF), aimed at harmonizing the different supply chain
        assessment and certification systems worldwide.
      </p>
      <h3 className="h-mt-40">AS9100D</h3>
      <p>
        <a href="https://www.sae.org/standards/content/as9100d/" target="_blank"
          rel="noopener noreferrer">AS9100D</a> is a widely adopted and standardized quality
        management system for the aerospace industry. It was released in October, 1999,
        by the Society of Automotive Engineers and the European Association of Aerospace
        Industries.
      </p>
      <p>
        AS9100 replaces the earlier AS9000 and fully incorporates the entirety of the
        current version of ISO 9001, while adding requirements relating to quality and
        safety. Major aerospace manufacturers and suppliers worldwide require compliance
        and/or registration to AS9100 as a condition of doing business with them.
      </p>
    </section>
    <Gallery />
    <Challenge
      onStartQuotingClick={customEvent(events.certificationsStartQuoting())}
      onContactUsClick={customEvent(events.certificationsContactUs())}
    />
  </Layout>
);

export default Certifications;
