import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import gallery14 from '@src/images/gallery14.jpg';
import gallery11 from '@src/images/gallery11.jpg';
import gallery3 from '@src/images/gallery3.jpg';
import gallery7 from '@src/images/gallery7.jpg';
import gallery9 from '@src/images/gallery9.jpg';
import gallery1 from '@src/images/gallery1.jpg';

const Gallery = () => (
  <div className="c-gallery">
    <div className="large">
      <LazyLoadImage effect="blur" src={gallery14} alt="Printed circuit boards" />
    </div>
    <div className="tall">
      <LazyLoadImage effect="blur" src={gallery11} alt="PCB testing" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery3} alt="PCB mounting" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery7} alt="Production line" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery9} alt="Assembly machine" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery1} alt="Assembly machine" />
    </div>
  </div>
);

export default Gallery;
